<template>
  <LayoutFilter
    :toggleCollapseEnable="false"
    :onFilter="ok"
    :onReset="resetForm"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="logFilterForm"
      label-width="70px"
      size="medium"
      class="filter-from"
    >
      <div class="filter-item">
        <el-form-item label="商家类型：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入内容"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker
          label="创建时间："
          start-prop="create_start_time"
          :start-time.sync="form.create_start_time"
          end-prop="create_end_time"
          :end-time.sync="form.create_end_time"
        />
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from '@/base/layout/LayoutFilter';
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker';
export default {
  props: {
    typeData: {
      type: Object,
    },
    uploadFilter:{
      type:Function,
      require:true
    }
  },
  data() {
    return {
      rules: {},
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs['logFilterForm'].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  computed: {
    form: {
      get() {
        return this.typeData;
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>

<style>
</style>