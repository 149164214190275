<template>
  <div class="list-page">
    <list-filter :typeData="typeData" :uploadFilter="ok"></list-filter>
    <list-tabs
      v-model="typeData.tab"
      :tabs="tabArr"
      @tab-click="handleClick"
    ></list-tabs>

    <div class="list-content" v-loading="loading">
      <div class="list-btn" v-if="typeData.tab === 'normal'">
        <el-button type="primary" size="small" @click="addType">新增</el-button>
        <el-button type="danger" size="small" @click="handleDel"
          >删除</el-button
        >
      </div>
      <div class="list-btn" v-if="typeData.tab === 'deleted'">
        <el-button type="primary" size="small" @click="putBack">恢复</el-button>
        <el-button type="danger" size="small" @click="handleDel"
          >删除</el-button
        >
      </div>

      <el-table
        :data="tbody"
        class="thead-light"
        v-if="typeData.tab === 'normal'"
        @selection-change="handleSelectionChange"
      >
        <!-- 勾选-->
        <el-table-column
          fixed
          type="selection"
          min-width="55"
        ></el-table-column>
        <el-table-column
          label="操作"
          :min-width="'170'"
          fixed="right"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small">编辑</el-button>
            <el-button type="text" size="small" @click="move(scope.row)"
              >转移</el-button
            >
            <el-button type="text" size="small" @click="del(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
            align="center"
          >
          </el-table-column>
        </template>
      </el-table>
      <el-table
        :data="tbody"
        class="thead-light"
        v-if="typeData.tab === 'deleted'"
        @selection-change="handleSelectionChange"
      >
        <!-- 勾选-->
        <el-table-column
          fixed
          type="selection"
          min-width="55"
        ></el-table-column>
        <!-- 操作 -->
        <el-table-column
          label="操作"
          :min-width="'120'"
          fixed="right"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small">编辑</el-button>
            <el-button type="text" size="small" @click="del(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
            align="center"
          >
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination
      :total="pageData.total"
      :page="typeData.page"
      :change="changPage"
    />
    <addType
      :openAddDialog="openAddDialog"
      @closeAddDialog="closeAddDialog"
    ></addType>
    <moveType
      :openMoveDialog="openMoveDialog"
      @closeMoveDialog="closeMoveDialog"
      :tbody="tbody"
      @submit="submit"
    ></moveType>
  </div>
</template>

<script>
import listFilter from '../../components/TypeList/ListFilter';
import addType from '../../components/TypeList/addType';
import moveType from '../../components/TypeList/moveType';
import Pagination from '@/base/components/Default/Pagination';
import { typeList, move, typeSoftDel, typeDel,typePutBack,all } from '../../api/type-list/list';
export default {
  data() {
    return {
      typeData: {
        keyword: '',
        create_start_time: -1,
        create_end_time: -1,
        tab: 'normal',
        page: 1,
        page_size: 15,
        order_by: 'create_time',
        is_desc: 1,
      },
      tabArr: [
        { label: '全部', name: 'normal' },
        { label: '回收站', name: 'deleted' },
      ],
      thead: [
        { label: '类型ID', prop: 'id', minWidth: '120' },
        { label: '商家类型', prop: 'name', minWidth: '170' },
        { label: '商家数量', prop: 'shops_count', minWidth: '120' },
        { label: '排序', prop: 'sort', minWidth: '120' },
        { label: '创建时间', prop: 'create_time', minWidth: '250' },
      ],
      pageData: {
        total: 0,
      },
      loading: false,
      tbody: [],
      ids: [],
      openAddDialog: false,
      openMoveDialog: false,
      loading: false,
      moveSourceId: '',
    };
  },
  mounted() {
    this.getTypeList(this.typeData);
    all().then((res)=>{
      console.log("all",res);
    })
  },
  methods: {
    // 获取类型列表
    getTypeList(data) {
      typeList(data).then((res) => {
        this.loading = false;
        this.tbody = res.data.data;
        this.pageData.total = res.data.total;
      });
    },
    ok(e) {
      this.loading = true;
      this.typeData = { ...this.typeData, ...e, page: 1 };
      this.getTypeList(this.typeData);
    },
    // tab切换
    handleClick(e) {
      this.loading = true;
      this.typeData.tab = e.name;
      this.getTypeList(this.typeData);
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.ids = [];
      } else {
        this.ids = [];
        // 筛选id
        selection.forEach((item) => {
          this.ids.push(item.id);
        });
      }
    },
    // 新增
    addType() {
      this.openAddDialog = true;
    },
    closeAddDialog(val) {
      if (val == false) {
        this.openAddDialog = val;
      } else {
        this.getTypeList(this.typeData);
      }
    },
    closeMoveDialog(val) {
      if (val == false) {
        this.openMoveDialog = val;
      } else {
        this.getTypeList(this.typeData);
      }
    },
    // 分页查询
    changPage(e) {
      this.typeData = { ...this.typeData, ...e };
      this.getTypeList(this.typeData);
    },
    // 转移
    move(row) {
      console.log(row.id);
      this.moveSourceId = row.id;
      this.openMoveDialog = true;
    },
    // 转移分类确定
    submit(target_id) {
      console.log(this.moveSourceId, target_id);
      let data = {
        id: this.moveSourceId,
        target_id: target_id,
      };
      move(data).then((res) => {
        console.log('res', res);
        if (res.msg == '保存成功') {
          this.$message({
            message: res.msg,
            type: 'success',
          });
        }
      });
      this.openMoveDialog = false;
    },
    // 删除
    del(row) {
      if (this.typeData.tab === 'normal') {
        this.$msgbox
          .confirm('确定要将此数据移入回收站吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.loading = true;
            this.ids.push(row.id);
            typeSoftDel({ ids: this.ids }).then((res) => {
              if (res.msg == '删除成功') {
                this.$message({
                  message: res.msg,
                  type: 'success',
                });
                this.ids = [];
              }
              this.getTypeList(this.typeData);
            });
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        this.$msgbox
          .confirm('确定要将此数据永久删除吗？', '提示', {
            type: 'error',
          })
          .then((res) => {
            this.ids.push(row.id);
            typeDel({ ids: this.ids }).then((res) => {
              this.loading = true;
              if (res.msg == '删除成功') {
                this.$message({
                  message: res.msg,
                  type: 'success',
                });
              }
              this.ids = [];
              this.getTypeList(this.typeData);
            });
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 批量删除
    handleDel() {
      if (this.ids.length) {
        if (this.typeData.tab == 'normal') {
          this.$msgbox
            .confirm('确认要将选中的数据移入回收涨吗？', '提示', {
              type: 'info',
            })
            .then((res) => {
              this.loading = true;
              typeSoftDel({ ids: this.ids }).then((res) => {
                if (res.msg == '删除成功') {
                  this.$message({
                    message: res.msg,
                    type: 'success',
                  });
                  this.ids = [];
                }
                this.getTypeList(this.typeData);
              });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm('确认要将选中的数据永久删除吗？', '提示', {
              type: 'info',
            })
            .then((res) => {
              typeDel({ ids: this.ids }).then((res) => {
                this.loading = true;
                if (res.msg == '删除成功') {
                  this.$message({
                    message: res.msg,
                    type: 'success',
                  });
                }
                this.ids = [];
                this.getTypeList(this.typeData);
              });
            })
            .catch((err) => {});
        }
      } else {
        this.$message.info('请选择数据项');
      }
    },
    // 批量恢复
    putBack() {
      if (this.ids.length) {
        this.$msgbox
          .confirm('确定要将选中的数据恢复吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.loading = true;
            typePutBack({ ids: this.ids }).then((res) => {
              console.log(res);
              if (res.msg == '操作成功') {
                this.$message({
                  message: res.msg,
                  type: 'success',
                });
                this.ids = [];
              }
              this.getTypeList(this.typeData);
            });
          });
      } else {
        this.$message.info('请选择数据项');
      }
    },
  },
  components: {
    listFilter,
    addType,
    Pagination,
    moveType,
  },
};
</script>

<style scoped>

</style>
