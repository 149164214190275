<template>
  <el-dialog
    title="分类转移"
    :visible.sync="isShow"
    width="450px"
    :show-close="true"
    append-to-body
    class="dialog-vertical"
    center
    @close="cancel"
  >
    <el-form size="medium" :model="form" ref="form" label-width="100px">
      <el-form-item label="分类名称：" prop="type" required>
        <el-select
          v-model="form.type"
          placeholder="请选择"
          @change="selectChange"
        >
          <el-option
            v-for="item in tbody"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ['openMoveDialog', 'tbody'],
  data() {
    return {
      isShow: this.openMoveDialog,
      form:{
        type:""
      },
      target_id:""
    };
  },
  methods: {
    // 取消
    cancel() {
      this.$emit('closeMoveDialog', false);
      
    },
    // 确定
    submit() {
        this.$emit("submit",this.target_id)
    },
    selectChange(val) {
      this.target_id = val
    },
  },
  watch: {
    openMoveDialog(val) {
      this.isShow = val;
    },
  },
};
</script>

<style>
</style>