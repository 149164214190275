<template>
  <el-dialog
    title="添加分类"
    :visible.sync="isShow"
    width="450px"
    :show-close="true"
    append-to-body
    class="dialog-vertical"
    center
    @close="cancel"
  >
    <el-form size="medium" :model="form" ref="form" label-width="100px" @submit.native.prevent>
      <el-form-item label="分类名称：" required>
        <el-input placeholder="请输入" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="排序：" required>
        <el-input type="number" placeholder="请输入" v-model="form.sort"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addType } from '../../api/type-list/list';
export default {
  props: ['openAddDialog'],
  data() {
    return {
      isShow: this.openAddDialog,
      form: {
        name: '',
        sort: '',
      },
    };
  },
  methods: {
    // 取消
    cancel() {
      this.$emit('closeAddDialog', false);
      this.form.name =""
      this.form.sort = ""
    },
    // 确定
    submit() {
      this.$emit('closeAddDialog', false);
      this.loading = true;
      let data = {
        id: '',
        name: this.form.name,
        sort: this.form.sort,
      };
      
      addType(data).then((res) => {
        
        
        if (res.msg == '保存成功') {
          this.$message({
            message: res.msg,
            type: 'success',
          });
        }
        this.$emit("closeAddDialog",true)
      });
      this.form.name =""
      this.form.sort = ""
    },
  },
  watch: {
    openAddDialog(val) {
      this.isShow = val;
    },
  },
};
</script>

<style>
</style>